<template>
  <section id="app">
    <TepHeader />
    <TepMenu />
    <router-view></router-view>
    <TepFooter />
  </section>
</template>

<script>
import TepHeader from "@/components/Header.vue";
import TepMenu from "@/components/Menu.vue";
import TepFooter from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    TepHeader,
    TepMenu,
    TepFooter,
  },
};
</script>

<style lang="scss">
@import "@/scss/fonts.scss";
@import "@/scss/global.scss";
</style>
