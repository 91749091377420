import Vue from "vue";
import VueRouter from "vue-router";

// Code-Splitting
const Home = () => import("../views/Home.vue");
const About = () => import("../views/About.vue");
const Contact = () => import("../views/Contact.vue");
const Styles = () => import("../views/Styles.vue");
const Team = () => import("../views/Team.vue");
const TeamMember = () => import("../views/TeamMember.vue");
const Operations = () => import("../views/Operations.vue");
const Water = () => import("../views/Water.vue");
const News = () => import("../views/News.vue");
const Community = () => import("../views/Community.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/styles",
    name: "Styles",
    component: Styles,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
  },
  {
    path: "/team/:id",
    name: "TeamMember",
    component: TeamMember,
  },
  {
    path: "/operations",
    name: "Operations",
    component: Operations,
  },
  {
    path: "/operations/:type",
    name: "Water",
    component: Water,
  },
  {
    path: "/community",
    name: "Community",
    component: Community,
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: "/",
  routes,
  // scrollBehavior() {
  //     return { x: 0, y: 0 };
  // },
});

export default router;
