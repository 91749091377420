<template>
  <div class="footer">
    <div class="content_wrapper">
      <router-link to="/contact">Contact us</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tep-Footer",
  props: {},
};
</script>
<style scoped lang="scss">
.footer {
  font-family: "Nunito Sans";
  width: 100%;
  height: auto;
  min-height: 150px;
  background-color: var(--light_grey);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    align-self: center;
    position: relative;
    text-decoration: none;
    color: var(--link_color);
    transition: all 0.3s;
    margin-right: 20px;
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      width: 0%;
      height: 1px;
      background-color: var(--link_color);
      bottom: -1px;
      left: 0px;
      transition: all 0.3s;
    }
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: var(--link_color_underline);
      bottom: -1px;
      left: 0px;
    }
  }

  a:hover {
    color: var(--link_color_hover);
    border-bottom-color: var(--link_color);
    &:after {
      width: 100%;
    }
  }
}
</style>
