<template>
  <div class="header">
    <div class="content_wrapper">
      <router-link to="/">
        <img class="logo" src="@/assets/images/logo.svg" />
      </router-link>
      <!-- <router-link to="/login">
        <a class="link">Log in</a>
      </router-link> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Tep-Header",
  props: {},
};
</script>
<style scoped lang="scss">
.header {
  width: 100%;
  height: 70px;
  background-color: var(--light_grey);

  .logo {
    height: 45px;
    width: auto;
    margin-top: 12px;
  }
  .link {
    margin-top: 20px;
    float: right;
  }
}
</style>
