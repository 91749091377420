<template>
  <div class="menu prevent-select">
    <div class="desktop">
      <div class="content_wrapper">
        <div class="wrapper">
          <div :class="['ico', { expanded: topOffset > 70 }]"></div>

          <div class="menu_links">
            <template>
              <router-link
                v-for="item in items"
                class="menu_link"
                :to="item.link"
                :key="item.index"
              >
                {{ item.label }}</router-link
              >
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile">
      <div class="content_wrapper">
        <div class="wrapper">
          <div
            :class="['ico', { expanded: topOffset > 70 }]"
            @click="toggleMenu"
          ></div>
          <div class="toggle" @click="toggleMenu">Menu</div>
        </div>
        <div class="drawer" @click="toggleMenu" :class="{ active: menuOpen }">
          <div class="menu_links">
            <template>
              <router-link
                v-for="item in items"
                class="menu_link"
                :to="item.link"
                :key="item.index"
              >
                {{ item.label }}</router-link
              >
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tep-Menu",
  props: {},
  data() {
    return {
      topOffset: 0,
      menuOpen: false,
      items: [
        { label: "Home", link: "/", icon: "" },
        { label: "About", link: "/about", icon: "" },
        { label: "Operations", link: "/operations", icon: "" },
        { label: "Team", link: "/team", icon: "" },
        { label: "Community", link: "/community", icon: "" },
        { label: "News", link: "/news", icon: "" },
        { label: "Contact", link: "/contact", icon: "" },
      ],
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },

    setPageOffset() {
      let pageYOffset =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop;

      this.topOffset = pageYOffset;
    },
  },
  mounted() {
    document.addEventListener("scroll", this.setPageOffset);
  },
  destroyed() {
    document.removeEventListener("scroll", this.setPageOffset);
  },
};
</script>
<style scoped lang="scss">
// Desktop and Mobile
.menu {
  font-family: "Nunito Sans";
  font-size: 17px;
  z-index: 100000;
  width: 100%;
  background-color: #ffffff;
  height: 50px;
  line-height: 50px;
  position: sticky;
  top: 0;
  box-shadow: 0 5px 5px -5px gray;
}
.ico {
  opacity: 0.15;
  height: 25px;
  width: 30px;
  margin-top: 14px;
  background-image: url("../assets/images/logo.svg");
  filter: grayscale(100%);
  background-size: auto 100%;
  transition: all 0.5s;
}
.menu_links {
  .menu_link {
    text-decoration: none;
    color: var(--link_color);
    cursor: pointer;
    transition: all 0.3s;
  }
  a:hover,
  a:focus,
  a:active {
    color: var(--link_color_hover);
  }
}

// Desktop
.desktop {
  .wrapper {
    position: absolute;
    width: auto;
    height: 100%;
    .ico {
      float: left;
      position: relative;
      &.expanded {
        filter: grayscale(0);
        opacity: 1;
        width: 120px;
      }
    }
    .menu_links {
      position: relative;
      float: left;
      margin-left: 10px;
      height: 50px;
      overflow: hidden;
      .menu_link {
        position: relative;
        margin: 0 10px;
        &:after {
          position: absolute;
          display: block;
          content: "";
          width: 0;
          height: 3px;
          top: 25px;
          background-color: var(--link_color_underline);
          transition: all 0.3s;
          left: 0;
        }
      }
      .router-link-exact-active {
        &:after {
          width: 104%;
        }
      }
    }
  }
}

// Mobile
.mobile {
  display: none;
  cursor: pointer;

  .wrapper {
    position: absolute;
    height: 100%;
    .toggle {
      margin-left: 25px;
      display: inline-block;
      cursor: pointer;
    }
    .ico {
      float: left;
      position: relative;
      &.expanded {
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }

  .drawer {
    position: absolute;
    top: 50px;
    left: 0px;
    background-color: #ffffff;
    box-shadow: 0 5px 5px -5px gray;
    height: 0;
    width: 100%;
    overflow: hidden;
    padding-left: 114px;
    padding-bottom: 0px;
    &.active {
      height: auto;
      padding-bottom: 50px;
    }
    .menu_links {
      position: relative;
      .menu_link {
        height: 40px;
        display: block;
        position: relative;
        transition: all 0.5s;
        &::before {
          position: absolute;
          display: block;
          content: "";
          width: 3px;
          height: 0px;
          top: 16px;
          left: -15px;
          background-color: var(--link_color_underline);
          transition: all 0.3s;
        }

        &:hover {
          &::before {
            height: 20px;
          }
        }
      }
      .router-link-exact-active {
        color: var(--link_color_underline);
        font-weight: 600;
        &::before {
          position: absolute;
          display: block;
          content: "";
          width: 3px;
          height: 20px;
          top: 16px;
          left: -15px;
          background-color: var(--link_color_underline);
          transition: all 0.3s;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
</style>
